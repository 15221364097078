import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import '../styles/verticalSlider.css';

import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Rellax from 'rellax';
// import slide1 from "../images/images/slideimg.png"
import SwiperCore, { Mousewheel,Navigation, Pagination, Scrollbar } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

import bigCircle from '../images/bigCircle.svg';
import smallCircle from '../images/smallCircle.svg';
import star from '../images/star.svg';

SwiperCore.use( [ Navigation, Pagination, Scrollbar, Mousewheel ] );


let getformattedData = ( data, sectionsArray ) => {
  let output = {};
  let column;

  for ( let col = 1; col < 3; col++ ) {
    output['column_' + col] = {};
    column = data.filter( ( item ) => item.node.column == col && item.node );
    let len = column.length;
    for ( let j = 0; j < len; j++ ) {
      output['column_' + col][column[j].node.style_category] = column[j].node;
    }
  }
  return output;
};

const VerticalSlider = () => {
  function getDirection() {
    // var windowWidth = 767; // window.innerWidth;
    // var direction = 'horizontal'; // window.innerWidth >= 767 ? 'vertical' : 'horizontal';
    // var windowWidth = window.innerWidth;
    var direction = 'horizontal';
    // Check if window is defined (so if in the browser or in node.js).
    const isBrowser = typeof window !== 'undefined';
    if ( isBrowser ) {
      direction = window.innerWidth >= 767 ? 'vertical' : 'horizontal';
    }
    return direction;

  }

  const [ formattedData, setData ] = useState( null );

  const data = useStaticQuery( graphql`
  query {
    allStrapiPageData(filter: {page_name: {eq: "home_page"}, status: {eq: "Production"} }) {
      edges {
        node {
          data_category
          column
          content
          style_category
          attribute_1_value
          attribute_2_value
          attribute_1_key
          attribute_2_key
        }
      }
    }
  }` );

  useEffect( () => {
    new Rellax( '.rellax', { // <---- Via class name
      speed: 5
      // horizontal: true,
      // vertical: true
    } );
  }, [] );

  useEffect( () => {
    let formatted = getformattedData( data.allStrapiPageData.edges, ['why_home_life_together'] );
    let arr = Object.keys( formatted ).map( col => formatted[col] );
    setData( arr );
  }, [data] );


  return (
    <Swiper
      // spaceBetween={50}
      slidesPerView={1}
      mousewheel={{ invert: false, releaseOnEdges: true }}
      // direction="vertical"
      direction={getDirection()}
      pagination={{ clickable: true }}
      onSlideChange={() => {}}
      onSwiper={( swiper ) => {}}
      observer={true}
      observeParents={true}
      // freeMode= "true"
    >
      {formattedData && formattedData.map( ( article, index ) =>
        ( <SwiperSlide key={index}>
          <div className="container common-container-space">
            <div className="row h-100 align-items-center btopspace">
              <div className="col-lg-6 col-md-6 d-flex flexend align-items-center title desk-view position-relative">
                {/* <div className="imgbg"></div> */}

                <img className="imgbg" src={article.title.attribute_1_value.replace( 'jpg', 'webp' )} alt={article.title.attribute_2_value} />

                <img src={smallCircle} alt="Bubble Life Insurance" className="smallCircle rellax" data-rellax-speed="-2" data-rellax-percentage="-.3" />

                <img src={bigCircle} alt="Bubble Life Insurance" className="bigCircle rellax" data-rellax-speed="-2" data-rellax-percentage="-.3" />

                <img src={star} alt="Bubble Life Insurance" className="star rellax" data-rellax-speed="-2" data-rellax-percentage="-.3" />
              </div>

              <div className="col-lg-6 col-md-6 col-12 content">
                <div className="d-flex align-items-center flexwrap rightcontent">
                  <h3>{article.title.content}</h3>

                  <p>{article.card_text.content}</p>
                </div>
              </div>

              <div className="col-12 d-flex flexend align-items-center title mob-view position-relative">
                <img className="imgbg" src={article.title.attribute_1_value.replace( 'jpg', 'webp' )} alt={article.title.attribute_2_value} />

                <img src={smallCircle} alt="Bubble Life Insurance" className="smallCircle rellax" data-rellax-speed="-2" data-rellax-percentage="-.3" />

                <img src={bigCircle} alt="Bubble Life Insurance" className="bigCircle rellax" data-rellax-speed="-2" data-rellax-percentage="-.3" />

                <img src={star} alt="Bubble Life Insurance" className="star rellax" data-rellax-speed="-2" data-rellax-percentage="-.3" />
              </div>
            </div>
          </div>

        </SwiperSlide> )
      )
      }
    </Swiper>
  );
};

export default VerticalSlider;
